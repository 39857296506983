/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { Store, UpdateStore } from "../StoreContext";
import scrollToTop from "../utils/ScrollTop";
import Dummy from "../assets/img/dummy.png";
import MainMobile from "../assets/img/mobileform.png";
import qarrow from "../assets/icons/qarrow.png";
import Grid from "@material-ui/core/Grid";
import QuestionsTimer from "./QuestionsTimer";
import TimerDialog from "./TimerDialoge";
import useState from "react-usestateref";
import { TextareaAutosize } from "@mui/material";

window.addEventListener("beforeunload", (ev) => {
  ev.preventDefault();
  return (ev.returnValue = "Are you sure you want to close?");
});

const Questions = ({ step, setStep, setActiveStep }) => {
  // init context

  const updateStore = UpdateStore();
  const { questions, user } = Store();
  // state init
  const seconds = parseInt(user?.time_in_minutes) * 60;
  const [lastQuestionIndex] = useState(questions.length - 1);
  const [open, setOpen] = React.useState(false);
  const [duration, setDuration] = useState(seconds ? seconds : 2400);
  const [answersArray, setAnswersArray, ref] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [inputanswer, setInputanswer] = useState({ text: "" });
  const groupedObjects = questions.reduce((acc, obj) => {
    const { category } = obj;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(obj);
    return acc;
  }, {});
  const combinedObjects = Object.values(groupedObjects).reduce((acc, objs) => {
    acc.push(...objs);
    return acc;
  }, []);
  combinedObjects.reverse();
  let pendingQuestions = [...combinedObjects];

  //-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=
  const handleNext = () => {
    if (currentQuestionIndex < combinedObjects.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      updateStore({ answerIds: ref.current, pendingQuestions });
      setStep(3);
      setActiveStep(3);
    }
  };

  const handleOptionSelect = (option) => {
    const correctOptionObj = combinedObjects[
      currentQuestionIndex
    ]?.options.filter((obj) => obj.correct === true);
    const question = {
      text: combinedObjects[currentQuestionIndex]?.text,
      type: combinedObjects[currentQuestionIndex]?.type,
      category: combinedObjects[currentQuestionIndex]?.category,
      options: combinedObjects[currentQuestionIndex]?.options,
    };
    const newAnswer = {
      // user: user?._id,
      question,
      chosen_option: option?.text,
      correct_option:
        combinedObjects[currentQuestionIndex]?.type == "input"
          ? option?.text
          : correctOptionObj[0]?.text,
      correct:
        combinedObjects[currentQuestionIndex]?.type == "input"
          ? true
          : option.correct,
      user_input:
        combinedObjects[currentQuestionIndex]?.type == "input"
          ? option.text
          : "",
    };

    const currentAnswers = ref.current;

    setAnswersArray([...currentAnswers, newAnswer]);

    handleNext();
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  const options = combinedObjects[currentQuestionIndex]?.options || [];
  const shuffledOptions = shuffleArray([...options]); // Shuffled copy of the options array

  return (
    <>
      <Grid container spacing={2} className="mainquestionscreen">
        <Grid item xs={12} sm={4} className="">
          <img src={Dummy} className="dummyimgQuestion forweb" />
          <img src={MainMobile} className="dummyimgforQuestion formobile" />
        </Grid>
        <Grid item xs={12} sm={8} className="questionGrid">
          <p className="questionNum">
            {currentQuestionIndex + 1}/{lastQuestionIndex + 1}
          </p>

          <div className="questionDiv">
            <div className="question-container">
              <img src={qarrow} className="qboximg" />
              <div className="questionBox">
                <h2
                  className="questiontext"
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                >
                  {combinedObjects[currentQuestionIndex]?.text}
                </h2>
              </div>
            </div>
            {combinedObjects[currentQuestionIndex]?.type != "input" ? (
              <>
                {" "}
                <Grid container spacing={3} className="answerGrid">
                  {shuffledOptions?.map((ans) => (
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      className="option"
                      // onClick={() => handleSelectOption(ans)}
                      onClick={() => handleOptionSelect(ans)}
                      onCopy={(e) => {
                        e.preventDefault();
                        return false;
                      }}
                    >
                      {ans?.text}
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={12} className="inputGrid">
                  {/* <input
                    name="name"
                    type="String"
                    placeholder="Write your answer"
                    className="answerinputField"
                    value={inputanswer.text}
                    onChange={(e) =>
                      setInputanswer({ ...inputanswer, text: e.target.value })
                    }
                    // required
                  /> */}
                  <TextareaAutosize
                    className="answerinputField"
                    name="name"
                    type="String"
                    placeholder="Write your answer"
                    value={inputanswer.text}
                    onChange={(e) =>
                      setInputanswer({ ...inputanswer, text: e.target.value })
                    }
                    // required
                    aria-label="minimum height"
                    minRows={8}
                    maxLength={500}
                  />
                </Grid>
                <Grid xs={12} sm={12}>
                  <button
                    variant="contained"
                    className="answerSubmitButton"
                    size="large"
                    onClick={() => {
                      if (!inputanswer?.text) {
                        return toast.error("Answer is required");
                      }
                      handleOptionSelect(inputanswer);
                      setInputanswer({ text: "" });
                    }}
                  >
                    <b>Submit</b>
                  </button>
                </Grid>
              </>
            )}
          </div>
        </Grid>
        {!open && (
          <QuestionsTimer
            setStep={setStep}
            setActiveStep={setActiveStep}
            setOpen={setOpen}
            open={open}
            duration={duration}
            setDuration={setDuration}
          />
        )}
        <TimerDialog
          open={open}
          setOpen={setOpen}
          setStep={setStep}
          setActiveStep={setActiveStep}
          duration={duration}
          setDuration={setDuration}
        />
      </Grid>
    </>
  );
};

export default Questions;
