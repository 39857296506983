// let baseUrl = "http://localhost:5000/api/v1";
// let mediaUrl = "http://localhost:5000";

let baseUrl = "https://hiring-api-dev.falconweb.app/api/v1";
let mediaUrl = "https://hiring-api-dev.falconweb.app.app";

const appName = "hiring";
let env = "dev";

if (window.location.host === "hiring-dev.falconweb.app") {
  baseUrl = "https://hiring-api-dev.falconweb.app/api/v1";
  mediaUrl = "https://hiring-api-dev.falconweb.app";
} else if (window.location.host === "hiring-staging.falconweb.app") {
  baseUrl = "https://hiring-api-dev.falconweb.app/api/v1";
  mediaUrl = "https://hiring-api-dev.falconweb.app";
  env = "staging";
} else if (window.location.host === "cnxcat.com") {
  baseUrl = "https://api.cnxcat.com/api/v1";
  mediaUrl = "https://api.cnxcat.com";
  env = "prod";
}

export { baseUrl, mediaUrl, appName, env };
